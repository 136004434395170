/* src/Views/HomePage.css */
.flashcube-homepage {
    text-align: left;
    padding: 20px;
    margin: 0 auto;
    max-width: 1600px;
    font-family: 'Aptos', sans-serif;
}

/* Ensure there's space between sections */
.flashcube-homepage > * {
    margin-bottom: 0px; /* Add spacing between Topfold and ServicesSection */
}

html {
    scroll-behavior: smooth;
    scroll-padding-top: 80px; /* Adjust this value based on the height of your navbar */
  }
  
h1 {
    font-size: 48px;
    font-weight: bold;
}

h2 {
    font-size: 36px;
    font-weight: bold;
}

h3 {
    font-size: 32px;
    font-weight: normal;
    font-style: normal;
}
