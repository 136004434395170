/* Container that holds the sliding text */
.banner-container {
  width: 100%; /* Full screen width */
  overflow: hidden; /* Hide overflow so the boxes appear and disappear as they slide in/out */
  background-color: #ffffff; /* Optional background color */
  padding: 50px 0;
  position: relative;
}

/* The sliding banner row */
.sliding-banner {
  display: flex; /* Flexbox to align text boxes horizontally */
  width: max-content; /* Ensure content spans the width of all boxes */
  animation: slide-animation 90s linear infinite; /* Infinite animation */
}

/* Each text box inside the banner */
.banner-box {
  flex: 0 0 500px; /* Fixed width of 400px */
  text-align: left; /* Align text to the left */
  padding: 40px 20px; /* Adjust padding to make the boxes taller */
  font-size: 18px;
  font-weight: bold;
  background-color: white;
  color: #0d3b66;
  margin-right: 200px; /* Small space between boxes */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  position: relative;
  display: flex;
  align-items: center; /* Align content vertically */
  justify-content: flex-start; /* Align items horizontally */
  gap: 15px; /* Space between image and text */
}

/* Optional image styling */
.banner-image {
  width: 100px; /* Fixed image width */
  height: 100px; /* Fixed image height */
  margin-right: 20px;
  border-radius: 8px; /* Optional: Round the corners of the image */
  object-fit: cover; /* Ensure the image fits inside the container */
}

/* Keyframes for sliding effect */
@keyframes slide-animation {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-100%);
  }
}

/* Pause the animation on hover */
.banner-container:hover .sliding-banner {
  animation-play-state: paused;
}

.banner-box:hover {
  transform: scale(1.05); /* Slightly enlarge the box on hover */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

/* Responsive adjustments for smaller screens */
@media (max-width: 1024px) {
  .banner-box {
    flex: 0 0 300px; /* Make the box smaller for tablets and small laptops */
    padding: 60px 15px;
    font-size: 16px;
    margin-right: 30px;
  }
}

@media (max-width: 768px) {
  .banner-box {
    flex: 0 0 200px; /* Reduce the width further for mobile devices */
    padding: 40px 15px;
    font-size: 14px;
    margin-right: 20px;
  }
}

@media (max-width: 480px) {
  .banner-box {
    flex: 0 0 150px; /* Narrower width for very small devices */
    padding: 30px 10px;
    font-size: 12px;
    margin-right: 15px;
  }
}
