/* General styling for the Topfold section */
.Topfold {
  color: white;
  text-align: left;
  padding-bottom: 0px;
}

/* Navbar styling */
.Topfold-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  z-index: 1000; /* Ensure navbar stays on top of other elements */
  padding: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Add subtle shadow for better separation */
}

/* Inner navbar container */
.Topfold-navbar .navbar-container {
  max-width: 1600px;
  width: 100%;
  padding: 10px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Logo styling */
.navbar-logo {
  height: 50px;
}

/* Hamburger icon styling */
.hamburger {
  display: none; /* Hidden by default */
  font-size: 30px;
  cursor: pointer;
  color: #555;
}

/* Navbar links */
.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
  transition: all 0.3s ease;
}

.navbar-links li a {
  color: #555; /* Updated link color */
  text-decoration: none;
  font-size: 20px;
  transition: transform 0.1s ease-in-out, color 0.2s;
}

.navbar-links li a:hover {
  transform: scale(1.1);
  color: #555; /* Lighter blue for hover effect */
}

/* Responsive Design */

/* Tablets (max-width: 1024px, min-width: 769px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .navbar-logo {
    height: 40px; /* Smaller logo size for tablets */
  }

  .navbar-links {
    gap: 15px; /* Reduced gap for better fit */
    flex-direction: row;
  }

  .hamburger {
    display: none; /* No hamburger icon for tablets */
  }
}

/* Mobile Phones (max-width: 768px) */
@media (max-width: 768px) {
  .navbar-container {
    padding: 10px 20px;
    position: relative;
  }

  .hamburger {
    display: block;
    font-size: 30px;
    color: #555;
  }

  .navbar-links {
    display: none; /* Hide links by default for mobile */
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 10px;
    background-color: #0555;
    padding: 15px;
    border-radius: 10px;
    z-index: 1000;
  }

  .navbar-links.open {
    display: flex; /* Show links when the menu is toggled open */
  }

  .navbar-links li {
    margin: 10px 0;
  }

  .navbar-links li a {
    font-size: 18px;
    color: white;
    text-decoration: none;
  }

  .navbar-links li a:hover {
    color: #555;
  }
}

/* Hero section styling */
.Topfold-hero {
  background: url('../assets/images/Operator3.webp') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 500px;
  padding-left: 50px;
  padding-bottom: 40px;
  margin-top: 60px;
}

.hero-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 0px;
}

.hero-subtext {
  font-size: 28px;
  font-style: oblique;
}

/* Small adjustments for tablets (hero section) */
@media (min-width: 769px) and (max-width: 1024px) {
  .Topfold-hero {
    padding-top: 300px;
    padding-left: 30px;
  }

  .hero-title {
    font-size: 36px;
  }

  .hero-subtext {
    font-size: 24px;
  }
}

/* Small adjustments for mobile phones (hero section) */
@media (max-width: 768px) {
  .Topfold-hero {
    padding-top: 200px;
    padding-left: 20px;
  }

  .hero-title {
    font-size: 28px;
  }

  .hero-subtext {
    font-size: 20px;
  }
}
