/* Main Contact Form Container */
.contact-form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 60px;
  }
  
  .contact-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #0d3b66;
  }
  
  /* Feedback Status Messages */
  .status-message {
    text-align: center;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
  }
  
  .status-message.success {
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
  }
  
  .status-message.error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
  }
  
  /* Contact Form Styling */
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #0d3b66;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .form-group textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  /* CAPTCHA Styling */
  .captcha {
    text-align: center;
  }
  
  /* Submit Button Styling */
  .submit-button {
    background-color: #0284b9;
    color: white;
    padding: 15px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #026b94;
  }
  
  /* Responsive Design */
  /* Main Contact Form Container */
.contact-form-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 60px;
}

.contact-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #0d3b66;
}

/* Feedback Status Messages */
.status-message {
    text-align: center;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
}

.status-message.success {
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
}

.status-message.error {
    color: #721c24;
    background-color: #f8d7da;
    border: 1px solid #f5c6cb;
}

/* Contact Form Styling */
.contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-group {
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #0d3b66;
}

.form-group input,
.form-group textarea {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

/* CAPTCHA Styling */
.captcha {
    text-align: center;
}

/* Submit Button Styling */
.submit-button {
    background-color: #0284b9;
    color: white;
    padding: 15px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #026b94;
}

/* Responsive Design for Tablets */
@media (min-width: 1024px) and (max-width: 1024px) {
    .contact-form-container {
        padding: 30px 15px;
    }

    .form-group input,
    .form-group textarea {
        font-size: 15px;
    }

    .submit-button {
        font-size: 16px;
        padding: 12px;
    }

    .contact-form-container h2 {
        font-size: 26px;
    }
}

/* Responsive Design for Mobile Phones */
@media (max-width: 768px) {
    .contact-form-container {
        padding: 20px 10px;
    }

    .form-group input,
    .form-group textarea {
        font-size: 14px;
    }

    .submit-button {
        font-size: 16px;
        padding: 12px;
    }

    .contact-form-container h2 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    .status-message {
        font-size: 14px;
        padding: 8px;
    }
}

