/* Main About Section */
.about-section {
  background-color: #fff;
  margin: 40px 0;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px; /* Added padding to improve appearance on all screens */
}

/* Flex container for the content */
.about-content {
  display: flex;
  justify-content: flex-start; /* Align content to the start (left side) */
  align-items: flex-start; /* Align items to the top */
  gap: 40px; /* Increased gap for better spacing */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

/* Combine the image with the credentials */
.about-image-credentials {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 350px;
  margin: 0; /* Remove auto margin to align to the top of the text box */
}

/* Profile image */
.about-image img {
  width: 200px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* Credentials styling */
.credentials {
  text-align: center;
  margin-top: 20px;
}

.credentials h3 {
  font-size: 22px;
  color: #0d3b66;
  margin: 0;
}

.credentials .title {
  font-size: 16px;
  color: #555;
  margin-top: 5px;
}

.credential-links {
  margin-top: 15px;
}

/* Individual credential item */
.credential-item {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

/* LinkedIn icon and link styling */
.linkedin-icon {
  font-size: 20px; /* Adjust icon size */
  margin-right: 8px;
}

.linkedin-link {
  color: #0d3b66; /* Make LinkedIn link blue like a hyperlink */
  text-decoration: none;
}

.linkedin-link:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Email icon and link styling */
.fa-envelope {
  font-size: 20px; /* Adjust icon size */
  color: #0d3b66; /* Icon color */
  margin-right: 8px;
}

.email-link {
  color: #0d3b66; /* Make email link blue like a hyperlink */
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline; /* Add underline on hover */
}

/* Text inside the box */
.about-text-content {
  flex: 1;
  max-width: 1000px; /* Limit the width to make the text box smaller */
  margin-top: -40px;
  padding-top: 00px;
  padding-left: 40px;
  padding-right: 40px; 
  padding-bottom: 40px; /* Add left and right padding for better appearance */
  color: #0d3b66;
  font-size: 20px;
  line-height: 1.8;
}

/* Responsive layout for tablets (max-width: 1024px) */
@media (min-width: 789px) and (max-width: 1024px) {
  .about-content {
    flex-direction: column; /* Stack image and text vertically */
    align-items: center;
    padding: 20px; /* Adjust padding for smaller screens */
  }

  .about-text-content {
    text-align: center;
    font-size: 18px; /* Slightly reduce font size */
  }

  .about-image img {
    width: 250px; /* Resize image */
  }
}

/* Responsive layout for mobile phones (max-width: 768px) */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column; /* Stack image and text */
    align-items: center; /* Ensure everything is centered */
    padding: 15px;
  }

  .about-image-credentials {
    margin-bottom: 20px; /* Add spacing between image/credentials and the text content */
  }

  .about-image img {
    width: 180px; /* Resize image for mobile */
  }

  .about-text-content {
    font-size: 16px; /* Reduce font size for better readability */
    text-align: center;
    padding: 20px; /* Adjust padding to fit smaller screens */
  }

  .credentials h3,
  .credentials .title,
  .linkedin-link,
  .email-link {
    font-size: 16px; /* Adjust font size for mobile */
  }
}
