/* Services Section */
.ServicesSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  max-width: 1600px;
  padding-left: 0px;
  padding-right: 0px;
}

/* Service Highlight Section */
.service-highlight {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 50px;
}

/* Left side text content */
.highlight-text {
  flex: 1;
  max-width: 700px;
}

.highlight-text h2 {
  font-size: 36px;
  color: #0d3b66;
  margin-bottom: 20px;
}

.highlight-text p {
  font-size: 20px;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
}

.cta-button {
  background-color: #0284b9;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}

.cta-button:hover {
  background-color: #026b94;
}

/* Right side boxes (small icon boxes) */
.highlight-boxes {
  margin-top:50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  flex: 1;
  max-width: 800px;
}

.highlight-box {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.highlight-box i {
  font-size: 30px;
  color: #0284b9;
  margin-right: 10px;
  vertical-align: middle;
}

.highlight-box h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: #0d3b66;
}

.highlight-box p {
  font-size: 16px;
  color: #0d3b66;
  line-height: 1.5;
}

/* Service Items Container */
.services-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  max-width: 1600px;
  margin-top: 50px;
}

/* Service Item Styling (for large service boxes) */
.service-item {
  text-align: center;
  width: 30%; /* Adjusted width for responsiveness */
  transition: transform 0.1s ease, box-shadow 0.1s ease;
  overflow: hidden;
  position: relative;
}

.service-item img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-item h2 {
  margin-top: 15px;
  font-size: 28px;
  font-weight: bold;
  color: #0d3b66;
}

.service-info {
  max-height: 0; /* Hidden initially */
  overflow: hidden;
  opacity: 0;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  font-size: 18px;
  color: #0d3b66a1;
  line-height: 1.4;
  padding: 20px;
}

/* Hover Effect for Service Items */
.service-item:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.service-item:hover img {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Reveal the additional info on hover for service items */
.service-item:hover .service-info {
  max-height: 500px; /* Expands the box to reveal additional content */
  opacity: 1; /* Fades in the content */
}

/* Responsive Layout */

/* Tablets (max-width: 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .service-highlight {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .highlight-text {
    max-width: 90%;
    margin-bottom: 30px;
  }

  .highlight-boxes {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    max-width: 100%;
  }

  .service-item {
    width: 45%; /* Reduced width for better display on tablets */
    margin-bottom: 30px;
  }

  .service-item img {
    width: 90%; /* Scale down images slightly for tablets */
  }

  .service-info {
    font-size: 16px;
  }
}

/* Mobile Phones (max-width: 768px) */
@media (max-width: 768px) {
  .service-highlight {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .highlight-text {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .highlight-boxes {
    grid-template-columns: 1fr; /* Stack boxes vertically on mobile */
    gap: 20px;
    max-width: 100%;
  }

  .highlight-box {
    padding: 15px;
  }

  .highlight-box h3 {
    font-size: 18px;
  }

  .highlight-box p {
    font-size: 14px;
  }

  .services-container {
    flex-direction: column; /* Stack service items vertically on mobile */
    width: 100%;
  }

  .service-item {
    width: 100%;
    margin-bottom: 20px;
  }

  .service-item img {
    width: 80%; /* Scale down images for mobile screens */
    margin: 0 auto; /* Center the images */
  }

  .service-info {
    font-size: 14px;
  }

  .cta-button {
    font-size: 14px;
    padding: 10px 15px;
  }
}
