/* Footer container */
.footer {
    background-color: white;
    padding: 40px 20px;
    font-family: Aptos, sans-serif;
    max-width: 1600px;
    margin: 0 auto;
  }
  
  .footer-top {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
  }
  
  .footer-logo {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  
  .footer-top a {
    color: #555;
    font-size: 16px;
    text-decoration: none;
  }
  
  /* Placeholder for dynamic content */
  .footer-content-placeholder {
    height: 0px; /* Initially minimized */
    padding: 0;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: height 0.3s ease, padding 0.3s ease; /* Smooth transition when opening */
  }
  
  .footer-content-placeholder.open {
    height: auto; /* Expand when content is visible */
    padding: 20px 0; /* Add padding when expanded */
  }
  
  .text-box {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    width: 100%;
  }
  
  .text-box h4 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .text-box p {
    font-size: 14px;
    color: #555;
  }
  
  .close-btn {
    color: #555;
    cursor: pointer;
    margin-top: 20px;
    text-align: right;
    display: block;
  }
  
  .close-btn:hover {
    text-decoration: underline;
  }
  
  /* Footer bottom area */
  .footer-bottom {
    border-top: 1px solid #ccc;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-bottom p {
    margin: 0;
    color: #555;
  }
  
  .footer-bottom-links {
    display: flex;
    gap: 18px;
  }
  
  .footer-bottom-links a {
    text-decoration: none;
    color: #555;
  }
  
  .footer-bottom-links a:hover {
    text-decoration: underline;
  }
  
  /* Responsive design for tablets (max-width: 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
    .footer {
      padding: 30px 15px; /* Reduce padding for smaller screens */
    }
  
    .footer-top {
      padding-bottom: 15px;
      flex-direction: column; /* Stack the logo and link vertically */
      text-align: center;
    }
  
    .footer-logo {
      width: 24px; /* Increase logo size slightly for better visibility */
      height: 24px;
      margin-bottom: 10px;
    }
  
    .footer-top a {
      font-size: 18px; /* Adjust font size for better readability */
    }
  
    .footer-content-placeholder {
      padding: 10px 0; /* Reduce padding when expanded */
    }
  
    .footer-bottom {
      flex-direction: column; /* Stack copyright and links vertically */
      text-align: center;
    }
  
    .footer-bottom-links {
      margin-top: 15px;
    }
  
    .footer-bottom-links a {
      font-size: 14px; /* Slightly reduce the font size */
    }
  }
  
  /* Responsive design for mobile devices (max-width: 768px) */
  @media (max-width: 768px) {
    .footer {
      padding: 20px 10px; /* Further reduce padding */
    }
  
    .footer-top {
      padding-bottom: 10px;
    }
  
    .footer-content-placeholder {
      padding: 10px 0; /* Reduce padding when content is expanded */
    }
  
    .text-box {
      padding: 15px; /* Reduce padding inside the text box */
      max-width: 100%; /* Ensure full-width text box on small screens */
    }
  
    .text-box p {
      font-size: 13px; /* Adjust text size for readability */
    }
  
    .footer-bottom {
      padding-top: 15px; /* Adjust padding for the bottom section */
    }
  
    .footer-bottom-links {
      gap: 15px; /* Reduce spacing between the links */
      margin-top: 10px;
    }
  
    .footer-bottom-links a {
      font-size: 13px; /* Smaller font size for small screens */
    }
  }
  