ul {
    list-style-type: disc; /* Keep the bullets as discs */
    margin-left: 20px; /* Indentation for bullets */
    font-size: 14px; /* Adjust font size to match the paragraph */
    line-height: 1.6; /* Match line height with the paragraph */
    color: #555; /* Match font color */
  }
  
  li {
    margin-bottom: 10px; /* Add some space between list items */
  }
  